import { SupportedLocale } from 'constants/locales'
import { useActiveLocale } from 'hooks/useActiveLocale'
import { dynamicActivate, Provider } from 'lib/i18n'
import { ReactNode, useCallback } from 'react'
import { useUserLocaleManager } from 'state/user/hooks'

// Set the default locale to 'en-US'
const DEFAULT_LOCALE: SupportedLocale = 'en-US'

// Use the default locale if initialLocale is not set
dynamicActivate(DEFAULT_LOCALE)

export function LanguageProvider({ children }: { children: ReactNode }) {
  const locale = useActiveLocale()
  const [, setUserLocale] = useUserLocaleManager()

  const onActivate = useCallback(
    (locale: SupportedLocale) => {
      document.documentElement.setAttribute('lang', locale)
      setUserLocale(locale) // stores the selected locale to persist across sessions
    },
    [setUserLocale]
  )

  // Use the default locale if the active locale is not set
  const activeLocale = DEFAULT_LOCALE

  return (
    <Provider locale={activeLocale} onActivate={onActivate}>
      {children}
    </Provider>
  )
}
