import { Trans } from '@lingui/macro'
import { CurrencyAmount, Token } from '@uniswap/sdk-core'
import { ButtonPrimary } from 'components/Button'
import { LightCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import { RowBetween } from 'components/Row'
import { useTokenFromActiveNetwork } from 'lib/hooks/useCurrency'
import { themeVars } from 'nft/css/sprinkles.css'
import { useIsMobile } from 'nft/hooks'
import { useEffect, useState } from 'react'
import { useUSDPricesWithFallback } from 'state/cache/hooks'
import styled from 'styled-components'
import { ThemedText } from 'theme'
import { formatNumberOrString, NumberType } from 'utils/formatNumbers'

import { ToUSD } from './details'

type RewardDisplayProps = {
  rewardResults: string // Computed reward result as a string
  rewardToken: string // The token in which the rewards are issued
  onClaim: () => void // Handler for claim action
}

const RewardDisplay: React.FC<RewardDisplayProps> = ({ rewardResults, rewardToken, onClaim }: RewardDisplayProps) => {
  const rewardTokenQuery = useTokenFromActiveNetwork(rewardToken) as Token
  const [rewardsInUSD, setRewardsInUSD] = useState<string>('0')
  const [rewardsUSDValue, setRewardsUSDValue] = useState<string>('0')
  const isMobile = useIsMobile()
  const cachedTokenPrices = useUSDPricesWithFallback()

  useEffect(() => {
    if (rewardTokenQuery && rewardResults) {
      const rewardsAmount = CurrencyAmount.fromRawAmount(rewardTokenQuery, rewardResults)
      const value = formatNumberOrString(rewardsAmount.toSignificant(), NumberType.TokenNonTx)
      setRewardsInUSD(value)

      const usdValue = ToUSD(cachedTokenPrices, rewardsAmount)
      setRewardsUSDValue(usdValue ? `${formatNumberOrString(usdValue, NumberType.FiatTokenPrice)}` : '-')
    }
  }, [rewardResults, rewardTokenQuery, cachedTokenPrices])

  return (
    <RewardCard>
      <AutoColumn gap="12px">
        <ResponsiveRowBetween>
          <RewardTextContainer>
            <CurrencyLogo currency={rewardTokenQuery} size={isMobile ? '30px' : '40px'} />
            <ThemedText.DeprecatedLargeHeader
              fontWeight="600"
              fontSize={isMobile ? '18px' : '20px'}
              lineHeight={isMobile ? '24px' : '28px'}
            >
              <Trans>
                {rewardsInUSD} {rewardTokenQuery.symbol}
              </Trans>
            </ThemedText.DeprecatedLargeHeader>
            <USDValueText title={rewardsUSDValue}>≈ {rewardsUSDValue}</USDValueText>
            <ThemedText.DeprecatedLabel
              fontWeight="500"
              color={themeVars.colors.neutral1}
              fontSize={isMobile ? '12px' : '14px'}
            >
              <Trans>Your Available Rewards</Trans>
            </ThemedText.DeprecatedLabel>
          </RewardTextContainer>

          {/* Claim Button */}
          <StyledButtonPrimary
            padding={isMobile ? '4px 8px' : '6px 12px'}
            fontSize={isMobile ? '12px' : '14px'}
            style={{
              height: isMobile ? '28px' : '34px',
              alignSelf: 'center',
              width: isMobile ? '100px' : '120px', // Adjusted width for mobile
              borderRadius: '6px', // Slightly smaller radius for mobile
            }}
            onClick={onClaim}
          >
            <Trans>Claim</Trans>
          </StyledButtonPrimary>
        </ResponsiveRowBetween>
      </AutoColumn>
    </RewardCard>
  )
}

export default RewardDisplay

// Styled Components

const RewardCard = styled(LightCard)`
  background-color: ${({ theme }) => theme.surface1};
  padding: 12px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`

const RewardTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
`

const ResponsiveRowBetween = styled(RowBetween)`
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
`

const StyledButtonPrimary = styled(ButtonPrimary)`
  background-color: ${({ theme }) => theme.accent1};
  color: white;
  font-weight: 500;
  border: none;
  transition: background-color 0.2s, transform 0.1s;

  &:hover {
    background-color: ${({ theme }) => theme.accent2};
    transform: scale(1.03);
  }

  &:active {
    background-color: ${({ theme }) => theme.surface3};
    transform: scale(1);
  }
`

const USDValueText = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.neutral2};
  cursor: help;
`
